<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    format: {
      type: String,
      required: false,
      default: "dd/mm/yy"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    maxDate :{
      type : Date,
      required:false,
      default:undefined
    },
    minDate:{
      type : Date,
      required:false,
      default:undefined
    },
    selectionMode:{
      type : String,
      required: false,
      default: "single"
    }
  },
  methods: {},
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorMinDate() {
      return this.validator !== undefined && this.validator.minDate !== undefined && this.validator.minDate.$invalid && this.alreadySubmitted;
    },
    isValidatorMaxDate() {
      return this.validator !== undefined && this.validator.maxDate !== undefined && this.validator.maxDate.$invalid && this.alreadySubmitted;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    }
  }
}
</script>

<template>
  <div :class="this.className" class="form-group ak-calendar">

    <AkLabel :required="isRequired">{{ this.label }}</AkLabel>
    <Calendar v-model="currentValue" :class="{'p-invalid':isInvalid && alreadySubmitted, 'disabled':isDisabled}" :date-format=this.format
              :selectionMode="selectionMode"
              :disabled="isDisabled" :max-date=maxDate :min-date=minDate locale="en_GB"
              :placeholder="getPlaceholder" class="form-control"></Calendar>
    <small
        v-if="isValidatorRequired"
        class="p-error">{{
        $t("field_required", [this.label])
      }}</small>
    <small
        v-if="isValidatorMinDate"
        class="p-error">{{
        $t("error_min_date", [this.label])
      }}</small>
    <small
        v-if="isValidatorMaxDate"
        class="p-error">{{
        $t("error_max_date", [this.label])
      }}</small>

  </div>
</template>