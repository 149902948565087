import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
  findSyntheseByYear(year) {
    return Api().get("/bilanformation/synthese/" + year).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findSyntheseByCurrentYear() {
    return Api().get("/bilanformation/synthese").then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAll(filter) {
    return Api().get("/bilanformations", {params: removeEmptyParams(filter)}).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAllCurrent(filter) {
    return Api().get("/bilanformations/current", {params: removeEmptyParams(filter)}).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAllByYear(year) {
    return Api().get("/bilanformations/year/" + year).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAllYears() {
    return Api().get("/bilanformations/years/").then(res => res.data);
  },
  findById(id) {
    return Api().get("/bilanformation/" + id).then(res => this.formatRes(res.data));
  },
  findByCeiId(ceiId) {
    return Api().get("/bilanformations/cei/" + ceiId).then(res => this.formatRes(res.data));
  },
  findCurrentYear() {
    return Api().get("/bilanformation/currentyear").then(res => res.data);
  },
  create(bilanFormation) {
    return Api().post("/bilanformation", bilanFormation).then(res => this.formatRes(res.data));
  },
  update(bilanFormation) {
    return Api().put("/bilanformation/" + bilanFormation.id, bilanFormation).then(res => this.formatRes(res.data));
  },
  delete(bilanFormation) {
    return Api().delete("/bilanformation/" + bilanFormation.id).then(() => bilanFormation);
  },
  formatRes(e) {
    if (e.pevhDateParution) e.pevhDateParution = new Date(e.pevhDateParution);
    if (e.pevhDateValidation) e.pevhDateValidation = new Date(e.pevhDateValidation);
    return e;
  },
  download(year) {
      return Api().get("/bilanformation/download/" + year, {responseType: "blob"}).then(res => downloadData(res));
  }
}