<script>
import useVuelidate from '@vuelidate/core'
import {maxLength} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkCalendar from "@components/input/AkCalendar";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkInputNumber from "@components/input/AkInputNumber";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanFormationService from "@services/bilanFormationService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormView, AkFormSubmitted, AkCalendar, AkInputText, AkInputTextArea, AkInputNumber},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.update",
    }
  },
  data() {
    return {
      submitted: false,
      current: {}
    }
  },
  validations() {
    return {
      current: {
        materielCommentaire: {maxLength: maxLength(500)},
        circuitCommentaire: {maxLength: maxLength(500)},
        rivhCommentaire: {maxLength: maxLength(500)},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p1 = bilanFormationService.findById(this.$route.params.id);
    p1.then(data => this.current = data);
  
    let p2 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.formationReview.edit);
  
    Promise.all([p1, p2]).then(() => {
      this.getRef().hideLoader();
    });
  },
  methods: {
    update() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.update"));
        return;
      }
    
      this.getRef().showLoader(this.$refs.form)
      bilanFormationService.update(this.current).then(data => {
        let msg = this.$t("bilan.updated");
        this.$router.replace({path: `/bilan/formation/${data.id}/details`, query: {msg: msg}});
      }).catch(e => {
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error." + e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-4" :label="$t('ager_label')"/>
          <AkInputText :disabled=true v-model=current.uerLabel class-name="col-md-4" :label="$t('uer_label')"/>
          <AkInputText :disabled=true v-model=current.ceiLabel class-name="col-md-4" :label="$t('cei_label')"/>
        </div>

        <h5>{{ $t('bilan_formation.title1') }}</h5>

        <div class="form-row">
          <AkInputText
              v-model="current.gpsDiff"
              :submitted=this.submitted
              :label="$t('bilan_formation.gps_diff')"
              class-name="col-md-6"/>
          <AkInputText
              v-model="current.gpsForm"
              :submitted=this.submitted
              :label="$t('bilan_formation.gps_form')"
              class-name="col-md-6"/>
        </div>

        <h5>{{ $t('bilan_formation.title2') }}</h5>

        <div class="form-row">
          <AkCalendar
              v-model="current.pevhDateParution"
              :submitted=this.submitted
              :label="$t('bilan_formation.pevh_date_parution')"
              class-name="col-md-4"/>
          <AkCalendar
              v-model="current.pevhDateValidation"
              :submitted=this.submitted
              :label="$t('bilan_formation.pevh_date_validation')"
              class-name="col-md-4"/>
          <AkInputText
              v-model="current.pevhModeDiffusion"
              :submitted=this.submitted
              :label="$t('bilan_formation.pevh_mode_diffusion')"
              class-name="col-md-4"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title3') }}</h5>

        <div class="form-row">
          <AkInputText
              v-model="current.materielForme"
              :submitted=this.submitted
              :label="$t('bilan_formation.materiel_forme')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.materielCommentaire"
              :validator="v$.current.materielCommentaire"
              :submitted=this.submitted
              :label="$t('bilan_formation.materiel_commentaire')"
              class-name="col-md-6"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title4') }}</h5>

        <div class="form-row">
          <AkInputText
              v-model="current.circuitForme"
              :submitted=this.submitted
              :label="$t('bilan_formation.circuit_forme')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.circuitCommentaire"
              :validator="v$.current.circuitCommentaire"
              :submitted=this.submitted
              :label="$t('bilan_formation.circuit_commentaire')"
              class-name="col-md-6"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title5') }}</h5>

        <div class="form-row">
          <AkInputNumber
              v-model="current.rivhNb"
              :submitted=this.submitted
              :label="$t('bilan_formation.rivh_nb')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.rivhCommentaire"
              :validator="v$.current.rivhCommentaire"
              :submitted=this.submitted
              :label="$t('bilan_formation.rivh_commentaire')"
              class-name="col-md-6"/>
        </div>
        <div class="float-right">
          <button v-if=this.canEditFormation() @click=update() class="btn btn-primary" style="margin-left: 10px">{{ $t('update') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>